$icon-item-padding-h: 60px;
$icon-item-padding-v: 50px;
$icon-item-padding-sm-v: 30px;
$icon-item-padding-xs-v: 20px;

.icon-link-list.component.grid {
  .component-content {
    ul {
      margin-right: -$icon-item-padding-h;
      margin-bottom: -$icon-item-padding-v;
    }
    li {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: 25%;
      padding-right: $icon-item-padding-h;
      padding-bottom: $icon-item-padding-v;

      .icon-wrapper {
        font-size: 3rem;
        .fa-fw {
          width: auto;
        }
      }
    }

    // Styles for centre-aligned version
    ul.align-center {
      justify-content: center;

      .text-wrapper,
      .icon-wrapper {
        text-align: center;
      }
    }

    @include media-breakpoint-down(md) {
      li {
        width: 33.3333%;
      }
    }

    @include media-breakpoint-down(sm) {
      ul {
        margin-bottom: -$icon-item-padding-sm-v;
      }
      li {
        width: 50%;
        padding-bottom: $icon-item-padding-sm-v;
      }
    }

    @include media-breakpoint-down(xs) {
      ul {
        margin-right: 0;
        margin-bottom: -$icon-item-padding-xs-v;
      }
      li {
        width: 100%;
        padding-right: 0;
        padding-bottom: $icon-item-padding-xs-v;

        .field-description {
          padding: 0;
        }
      }
    }
  }
}

.icon-link-list.component.grid.width-narrow {
  .component-content {
    li {
      width: 33.3333%;
      @include media-breakpoint-down(sm) {
        width: 50%;
      }

      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }
  }
}

// Convert to a list view on mobile when this option is selected
@include media-breakpoint-down(xs) {
  .icon-link-list.component.grid.collapse-grid-to-list-mobile {
    @include icon-list-styles();

    // Left aligns the text for when a grid collapses to a list style on mobile
    ul.align-center .text-wrapper {
      text-align: left;
    }

    li .icon-wrapper {
      font-size: 100%;
      .fa-fw {
        width: 1.25em;
      }
    }
  }
}
